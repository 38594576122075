.color-primary {
  color: #51b55d;
}

.color-secondary {
  color: gray;
}

.color-tertiary {
  color: #18453B;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.overlay-image {
  width: 50%; /* or any value in pixels or any other units */
  height: 50%; /* or any value in pixels or any other units */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Futura';
}

.home-header {
  height: auto;
  font-family: 'Futura';
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


 /* podium */
#podium {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  position: relative;
}

.step {
  width: 200px;
  margin: 10px;
  background-color: #51b55d;
  position: relative;
  bottom: 0;
  margin-right: -10px;
}

.var-name {
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}

#first {
  height: 300px;
}

#second {
  height: 225px;
}

#third {
  height: 175px;
}

#horizontal-bar {
  width: 100%; /* sets the width of the bar to take up the full width of its parent container */
  height: 5px; /* sets the height of the bar */
  background-color: white; /* sets the background color of the bar */
  margin-top: 20px;
}

ol {
  list-style-position: inside;
}

/* end podium */

/* begin challenge */

/*challenge options*/
.challenges-container {
  width: 80%;
  margin: 0 auto;
}

.challenge {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
}

.completed {
  background-color: #f2f2f2;
}

.completed .btn-complete {
  display: none;
}

.btn-complete {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.challenge-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.challenge-description {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.acceptButton {
  width: 40%;
  height: 50px;
  text-align: center;
}

.ChallengeName {
  text-align: left;
  font-size: 20px;
}

  

/* end challenge */

.svg{ 
  -webkit-filter: invert(.75); /* safari 6.0 - 9.0 */
          filter: invert(.75);
}

h6 {
  padding: 25px;
  border-radius: 15px;
}

